<template>
    <div>
        <div class="container pb-5">
            <SectionTitle title="Comunicados de prensa y noticias" /><br />
            <div class="row">
                <div v-for="(noticia, index) in news" :key="index" class="col-6 col-sm-4">
                    <NewCard :noticia="noticia" :index="news.length - index" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NewCard from "@/components/NewCard.vue";
import { noticiasMixin } from "@/mixins/Noticias";

export default {
    mixins: [noticiasMixin],
    data() {
        return {
            news: this.getNoticias(),
        };
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        },
    },
    components: {
        NewCard,
    },
};
</script>
