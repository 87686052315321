<template>
    <div>
        <div v-if="noticia" class="container pb-5 mt-5">
            <div v-if="noticia.banner" class="text-center">
                <img :src="require('@/assets/noticias/' + noticia.banner)" alt="" class="img-fluid" />
            </div>
            <hr class="mb-2" />
            <p class="text-muted mb-2">Fecha de publicación: {{ noticia.fecha | date }}</p>
            <hr class="mt-0" />
            <h5 v-html="noticia.titulo" class="mb-3"></h5>
            <template v-if="noticia.textos && noticia.textos.length">
                <p v-for="(texto, index) of noticia.textos" :key="index" v-html="texto"></p>
            </template>
            <div class="mt-5">
                <div class="row">
                    <div v-for="(img, index) of noticia.images" :key="index" class="col-6 col-sm-3">
                        <a :href="require('@/assets/noticias/' + img)" :data-fancybox="`new-gallery`">
                            <img :src="require('@/assets/noticias/' + img)" alt="" class="img-fluid" />
                        </a>
                    </div>
                </div>
            </div>

            <br />
            <router-link :to="`/comunicado-prensa-noticias`" class="text-success"
                ><b><p class="mt-3">VER MÁS COMUNICADOS</p></b></router-link
            >
        </div>
    </div>
</template>
<script>
import { noticiasMixin } from "@/mixins/Noticias";

export default {
    mixins: [noticiasMixin],
    data() {
        return {
            noticia: null,
        };
    },
    mounted() {
        this.noticia = this.getNoticia(this.$route.params.id);
    },
};
</script>
