<template>
    <div>
        <div class="container pb-5">
            <SectionTitle title="Noticias" /><br />
            <div class="row">
                <div v-for="(noticia, index) in news" :key="index" class="col-sm-4">
                    <div class="card mb-4">
                        <div class="card-body">
                            <img
                                class="mb-4 img-fluid text-center"
                                style="height: 400px; overflow: hidden; width: 100%"
                                :src="require('@/assets/noticias/' + noticia.images[0])"
                                alt="Notica"
                            />
                            <button
                                class="btn btn-success btn-block"
                                data-toggle="modal"
                                :data-target="`#video-` + index"
                            >
                                Ver más
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div
                v-for="(noticia, index) in news"
                :key="index"
                class="modal fade bd-example-modal-xl"
                :id="'video-' + index"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-center">
                            <img
                                v-for="(imagen, index) of noticia.images"
                                :key="index"
                                class="mb-4 img-fluid"
                                :src="require('@/assets/noticias/' + imagen)"
                                alt="Notica"
                            />
                            <template v-if="noticia.links">
                                <a
                                    :href="link"
                                    v-for="(link, indexLink) of noticia.links"
                                    :key="'link-' + indexLink"
                                    target="_blank"
                                >
                                    {{ link }} <br />
                                </a>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                <div v-for="(noticia, index) in noticias" :key="index" class="col-sm-4">
                    <div class="card">
                        <div class="card-body">
                            <img class="mb-4 img-fluid" :src="require('@/assets/noticias/' + noticia.portada)" alt="Notica">
                            <p class="text-justify">{{ noticia.texto }}</p>
                            <router-link :to="`news-show/` + noticia.slug" class=" btn btn-info">Ver más</router-link>
                        </div>
                    </div> <hr>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
import { noticiasMixin } from "@/mixins/Noticias";

export default {
    mixins: [noticiasMixin],
    data() {
        return {
            news: this.getNoticias(),
            noticia: {
                portada: null,
            },
            noticias: [
                {
                    slug: "comision",
                    portada: "portada-comision.jpg",
                    texto: "Se realiza reunión de Comisionados del Programa Moscamed Regional con autoridades de Guatemala, México, Estados Unidos y Belice.",
                },
                {
                    slug: "directivos",
                    portada: "directivo-1.jpg",
                    texto: "Directivos de AGEXPORT realizan visita a planta de producción de mosca del mediterráneo estéril, el pino del programa Moscamed Barberena, Santa Rosa. Octubre 08, 2021",
                },
            ],
        };
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        },
    },
};
</script>

<style scoped>
hr {
    border-top: 1px solid white;
}
</style>
